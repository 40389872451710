import styled from 'styled-components'
import { color } from 'const/colors'
import media from 'styles/media'
import Button from 'components/Button/index.js'

export const Section = styled.section`
  display: flex;
  flex-flow: column;
  padding: 75px 392px 77px;
  background-color: ${color.primary.o150};

  ${media.desktop`
    padding: 75px 280px 77px;
  `}

  ${media.tablet`
    padding: 35px 24px 56px 24px;
  `}
`

export const MainWrapper = styled.div``

export const Heading = styled.h1`
  font-size: 44px;
  line-height: 64px;
  color: ${color.white.o100};
  margin: 0;

  ${media.tablet`
    font-size: 24px;
    line-height: 40px;
    font-weight: 400;
  `}
`

export const SubHeading = styled.h2`
  font-size: 20px;
  line-height: 28px;
  color: ${color.white.o50};
  margin-bottom: 19px;

  ${media.tablet`
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
  `}
`

export const BtnWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`

export const StyledBtn = styled(Button)`
  margin: 24px 24px 0 0;
  flex-shrink: 0;
  font-size: 18px;

  ${media.phone`
    /* used to override default Button component property */
    margin-bottom: 0;
  `}
`
