import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from 'layouts/Default'
import ServicesSection from 'components/Approach/Services'
import ContactSection from 'sections/Shared/ContactSection'
import HeaderSection from 'components/Section/headerSection'
import FeaturesSection from 'sections/BuildTeam/Features'
import LeadershipSection from 'sections/BuildTeam/Leadership'
import Overview1Section from 'sections/BuildTeam/Overview1'
import QuotesSection from 'sections/Shared/quotes'

const PageContainer = styled.div`
  & * {
    box-sizing: border-box;
  }
`

class HowWeWork extends React.Component {
  state = {
    isContactFormModalOpen: false,
  }

  toggleScrollBody = () => {
    this.setState({
      isContactFormModalOpen: !this.state.isContactFormModalOpen,
    })
  }

  buildOGMetadata() {
    const { elements } = this.props.data.kenticoCloudItemAboutCopy

    return {
      title: elements.page_metadata__opengraph_title.value,
      imageUrl: elements.page_metadata__opengraph_image.value[0].url,
      description: elements.page_metadata__opengraph_description.value,
    }
  }
  render() {
    const { isContactFormModalOpen } = this.state

    const {
      title,
      tagline,
      testimonials,
    } = this.props.data.kenticoCloudItemAboutCopy.elements

    const {
      services_tagline,
      services_title,
      services_transitioned_title,
      services_left_column,
      services_centre_column,
      services_right_column,
    } = this.props.data.kenticoCloudItemApproach.elements

    return (
      <Layout
        {...this.props}
        isContactFormModalOpen={isContactFormModalOpen}
        ogMetadata={this.buildOGMetadata()}
      >
        <Helmet>
          <link
            rel="canonical"
            href={`${process.env.GATSBY_ROBOTS_SITE_URL}/how-we-work`}
          />
        </Helmet>
        <PageContainer>
          <HeaderSection
            header
            headline={title.value}
            tagline={tagline.resolvedHtml}
            taglineOpacity={1}
            maxWidth={'910px'}
            width={'75%'}
          />
          <FeaturesSection {...this.props.data.kenticoCloudItemAboutCopy} />
          <LeadershipSection {...this.props.data.kenticoCloudItemAboutCopy} />
          {/* <ResourcePoolSection {...this.props.data.kenticoCloudItemAboutCopy} /> */}
          <Overview1Section {...this.props.data.kenticoCloudItemAboutCopy} />
          <QuotesSection testimonials={testimonials} mB="0px" />
          {/* <Overview2Section {...this.props.data.kenticoCloudItemAboutCopy} />           */}
          <ServicesSection
            tagline={services_tagline.value}
            title={services_title.value}
            transitionedTitle={services_transitioned_title.value}
            leftColumn={services_left_column.resolvedHtml}
            centreColumn={services_centre_column.resolvedHtml}
            rightColumn={services_right_column.resolvedHtml}
          />
          <ContactSection toggleScrollBody={this.toggleScrollBody} />
        </PageContainer>
      </Layout>
    )
  }
}

export default HowWeWork

HowWeWork.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  {
    kenticoCloudItemAboutCopy {
      elements {
        slug {
          value
        }
        title {
          value
        }
        tagline {
          value
          resolvedHtml
        }
        main_image {
          value {
            url
            name
          }
        }
        main_image_mobile {
          value {
            name
            url
          }
        }
        team_title {
          value
        }
        team_description {
          value
          resolvedHtml
        }
        team_members {
          id
          elements {
            name {
              value
            }
            job_title {
              value
            }
            description {
              resolvedHtml
              value
            }
            image {
              value {
                name
                url
              }
            }
          }
        }
        resource_pool_title {
          value
        }

        resource_pool_description {
          value
          resolvedHtml
        }
        overview_text_1 {
          resolvedHtml
          value
        }
        overview_image_1 {
          value {
            url
            name
          }
        }
        overview_title_2 {
          resolvedHtml
        }
        overview_image_2 {
          value {
            url
            name
          }
        }
        page_metadata__opengraph_title {
          value
        }
        page_metadata__opengraph_description {
          value
        }
        page_metadata__opengraph_image {
          value {
            url
          }
        }

        testimonials {
          elements {
            name {
              value
            }
            company {
              value
            }
            job_position {
              value
            }
            quote {
              value
            }
            full_quote {
              value
            }
          }
        }
      }
    }

    kenticoCloudItemApproach {
      elements {
        services_tagline {
          value
        }
        services_title {
          value
        }
        services_transitioned_title {
          value
        }
        services_left_column {
          resolvedHtml
        }
        services_centre_column {
          resolvedHtml
        }
        services_right_column {
          resolvedHtml
        }
      }
    }
  }
`
