import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Swiper from 'react-id-swiper'
import ScrollAnimation from 'react-animate-on-scroll'
import LazyLoad from 'react-lazyload'
import 'react-id-swiper/src/styles/css/swiper.css'
import { Autoplay, Pagination } from 'swiper/dist/js/swiper.esm'
import { Flex } from '@rebass/grid'
import Section from 'components/Section'
import PageSection from 'components/Section/pageSection'
import media from 'styles/media'
import { color } from 'const/colors'
import withCurrentBreakpoint from 'hoc/withCurrentBreakpoint'

const Quote = styled.div`
  font-size: 36px;
  line-height: 48px;
  padding-bottom: 40px;
  color: ${color.white.o100};

  ${media.tablet`
    font-size: 20px;
    line-height: 28px;
    padding: 0 24px 26px 24px;
  `}
`

const Author = styled.div`
  font-size: 18px;
  line-height: 28px;
  color: ${color.primary.o100};

  ${media.tablet`
    padding-bottom: 50px;
    font-size: 16px;
    line-height: 20px;
  `}

  ${media.phone`
    span:nth-child(2) {
      display: block;
    }
  `}
`

const Slide = styled(Section)`
  max-width: 622px;
  margin: 0 auto;
  height: 470px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.tablet`
    height: 300px;
  `}

  ${media.phone`
    height: 330px;
  `}
`

const Wrapper = styled(Flex)`
  align-items: center;
  background: ${color.black.o100};
  padding: 60px 0;
  margin-bottom: ${({ mB }) => mB || '140px'};

  & > div > * {
    text-align: center;
  }

  & > div > * > span {
    background: ${color.white.o75};
  }

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }

  ${media.tablet`
    margin-bottom: ${({ mB }) => mB || '54px'};
  `}
`

const renderTestimonials = (testimonials) => {
  let testimonialElements = []
  testimonials.map(({ elements: { quote, name, job_position, company } }) => {
    testimonialElements = [
      ...testimonialElements,
      <Section key={quote.value} width={1}>
        <Slide key={quote.value}>
          <Quote>&quot;{quote.value}&quot;</Quote>
          <Author>
            <span>
              {name.value}, {job_position.value},{' '}
            </span>
            <span>{company.value}</span>
          </Author>
        </Slide>
      </Section>,
    ]
  })
  return testimonialElements
}

const QuotesSection = ({ testimonials, mB }) => {
  const swiperParams = {
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    modules: [Autoplay, Pagination],
    // autoplay: {
    //   delay: 5500,
    //   disableOnInteraction: false,
    // },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
  }

  return (
    <PageSection>
      <LazyLoad offset={100}>
        <ScrollAnimation animateIn="fadeIn">
          <Wrapper mB={mB}>
            <Swiper {...swiperParams}>
              {renderTestimonials(testimonials)}
            </Swiper>
          </Wrapper>
        </ScrollAnimation>
      </LazyLoad>
    </PageSection>
  )
}

QuotesSection.propTypes = {
  testimonials: PropTypes.array,
  mB: PropTypes.string,
}

export default withCurrentBreakpoint(QuotesSection)
