import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Section,
  MainWrapper,
  Heading,
  SubHeading,
  BtnWrapper,
  StyledBtn,
} from './styles'
import { color } from 'const/colors'

import ContactFormModal from 'components/Modal/ContactFormModal'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

class ContactSection extends Component {
  state = {
    isContactFormModalOpen: false,
    subject: '',
    name: '',
    email: '',
    message: '',
  }

  closeContactFormModal = () => {
    this.setState(
      {
        isContactFormModalOpen: false,
        subject: '',
      },
      () => {
        this.props.toggleScrollBody(this.state.isContactFormModalOpen)
      }
    )
  }

  handleClick = (subject = '') => {
    this.setState(
      {
        isContactFormModalOpen: true,
        subject: subject,
      },
      () => {
        this.props.toggleScrollBody(this.state.isContactFormModalOpen)
      }
    )
  }

  render() {
    return (
      <>
        <Section>
          <MainWrapper>
            <Heading>How can we help?</Heading>
            <SubHeading>Tell us more about what you’re looking for…</SubHeading>
            <BtnWrapper>
              <StyledBtn
                secondaryTextColor={color.primary.o100}
                onClick={() => this.handleClick('Workspace Identity')}
              >
                Workspace Identity →
              </StyledBtn>
              <StyledBtn
                secondaryTextColor={color.primary.o100}
                onClick={() => this.handleClick('Consumer Identity')}
              >
                Consumer Identity →
              </StyledBtn>
              <StyledBtn
                secondaryTextColor={color.primary.o100}
                onClick={() => this.handleClick('Innovation team')}
              >
                Innovation team →
              </StyledBtn>
              <StyledBtn
                secondaryTextColor={color.primary.o100}
                onClick={() => this.handleClick()}
              >
                Something else →
              </StyledBtn>
            </BtnWrapper>
          </MainWrapper>
        </Section>
        <TransitionGroup>
          {this.state.isContactFormModalOpen && (
            <CSSTransition classNames="fade" timeout={300} unmountOnExit>
              <ContactFormModal
                closeContactFormModal={this.closeContactFormModal}
                subject={this.state.subject}
              />
            </CSSTransition>
          )}
        </TransitionGroup>
      </>
    )
  }
}

ContactSection.propTypes = {
  toggleScrollBody: PropTypes.func,
}

export default ContactSection
