import styled from 'styled-components'
import Section from 'components/Section'
import { color } from 'const/colors'
import media from 'styles/media'
import Tagline from 'components/Tagline'
import PageSection from 'components/Section/pageSection'

export const TaglineStyled = styled(Tagline)`
  margin-bottom: 0px;

  ${media.tablet`
    margin-bottom: 0px;
  `}
`

export const PageSectionStyled = styled(PageSection)`
  padding-bottom: 70px;

  ${media.tablet`
    padding-bottom: 0;
  `}
`

export const MembersContainer = styled.div`
  display: grid;
  grid-template-columns: 28% 28% 28%;
  column-gap: 8%;
`

export const Slide = styled(Section)`
  margin: 10px 0 0;
  /* ${({ teamMember }) => teamMember && `max-width: 360px;`} */

  ${media.tablet`
    margin: 10px 0;
  `}
`

export const CarouselImg = styled.img`
  overflow: hidden;
  margin-bottom: 16px;
`

export const MemberName = styled.h4`
  font-size: 20px;
  line-height: 28px;
  color: ${color.black.o100};
  margin: 0;

  ${media.tablet`
    font-size: 18px;
  `}
`

export const MemberDescription = styled.div`
  & ul {
    list-style: none;
    padding-left: 0;
  }

  & ul li {
    margin-bottom: 5px;
    opacity: 0.5;
  }

  & ul li:before {
    content: '→';
    margin-right: 8px;
  }
`
