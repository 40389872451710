import React from 'react'
import PropTypes from 'prop-types'
import Headline from 'components/Headline'
import Tagline from 'components/Tagline'
import { color } from 'const/colors'
import styled from 'styled-components'
import media from 'styles/media'

var TextTransition
var presets

const Container = styled.div`
  overflow: auto;
`

const HeadlineStyled = styled(Headline)`
  span {
    color: ${color.primary.o100};
    display: inline-block;

    ${media.phone`
      height: 48px;
      display: block;
    `}
  }
`

class ServicesHeader extends React.Component {
  state = { index: 0 }
  intervalId = 0

  componentDidMount() {
    if (
      typeof window !== `undefined` &&
      window &&
      this.props.transitionedText.length > 0
    ) {
      TextTransition = require('react-text-transition').default
      presets = require('react-text-transition').presets
      this.intervalId = setInterval(() => {
        this.setState({ index: this.state.index + 1 })
      }, 1500)
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  render() {
    const {
      headline,
      transitionedText,
      tagline,
      header = false,
      ...restProps
    } = this.props

    return (
      <Container {...restProps}>
        {tagline && (
          <Tagline noMb tabletMb="40px" {...restProps}>
            {tagline}
          </Tagline>
        )}
        {typeof TextTransition !== `undefined` ? (
          <HeadlineStyled header={header}>
            {headline}&nbsp;
            <span>
              <TextTransition
                noOverflow={true}
                direction="down"
                text={
                  transitionedText[this.state.index % transitionedText.length]
                }
                springConfig={presets.default} // eslint-disable-line no-undef
              />
            </span>
          </HeadlineStyled>
        ) : (
          <HeadlineStyled header={header}>
            {headline}
            {/* The next line will prevent the 'jump' on phone layouts if there is transitionedText */}
            {transitionedText.length !== 0 && <span />}
          </HeadlineStyled>
        )}
      </Container>
    )
  }
}

export default ServicesHeader

ServicesHeader.defaultProps = {
  transitionedText: [],
}

ServicesHeader.propTypes = {
  headline: PropTypes.string,
  tagline: PropTypes.string,
  header: PropTypes.bool,
  transitionedText: PropTypes.array,
}
