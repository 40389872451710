import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Header from 'components/Approach/ServicesHeader'
import { color } from 'const/colors'
import media from 'styles/media'

const Section = styled.section`
  display: flex;
  flex-flow: column;
  background-color: ${color.black.o5};
  width: 100%;
  padding: 65px 248px 120px;

  ${media.desktop`
    padding: 65px 136px 120px;
  `}

  ${media.tablet`
    padding: 55px 24px 83px;
  `}
`

const ColumnsWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  & h4 {
    color: ${color.black.o100};
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 20px;

    ${media.tablet`
      font-size: 20px;
      line-height: 28px;
    `}
  }

  & ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  & li {
    opacity: 0.5;
    color: ${color.black.o100};
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;

    ${media.tablet`
      font-size: 18px;
      line-height: 28px;
    `}
  }

  ${media.tablet`
    flex-flow: column;
  `}
`

const Column = styled.div`
  width: 32%;

  ${media.tablet`
    width: 100%;
  `}
`

const Services = ({
  tagline,
  title,
  transitionedTitle,
  leftColumn,
  centreColumn,
  rightColumn,
}) => {
  var transitionedArr = []
  if (transitionedTitle !== '') {
    transitionedArr = transitionedTitle.split('/')
  }

  return (
    <Section id="solutions-services">
      <Header
        header
        headline={title}
        tagline={tagline}
        transitionedText={transitionedArr}
      />
      <ColumnsWrapper>
        <Column
          id="workspace-identity"
          dangerouslySetInnerHTML={{ __html: leftColumn }}
        />
        <Column
          id="consumer-identity"
          dangerouslySetInnerHTML={{ __html: centreColumn }}
        />
        <Column
          id="engineering-teams"
          dangerouslySetInnerHTML={{ __html: rightColumn }}
        />
      </ColumnsWrapper>
    </Section>
  )
}

Services.propTypes = {
  tagline: PropTypes.string,
  title: PropTypes.string,
  transitionedTitle: PropTypes.string,
  leftColumn: PropTypes.string,
  centreColumn: PropTypes.string,
  rightColumn: PropTypes.string,
}

export default Services
