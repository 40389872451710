import React from 'react'
import PropTypes from 'prop-types'
import { Container, ImgWrapper, Img } from './styles'
import withCurrentBreakpoint from 'hoc/withCurrentBreakpoint'

const Features = (props) => {
  const {
    elements: { main_image, main_image_mobile },
    currentBreakpoint,
  } = props

  const srcMobile = `${main_image_mobile.value[0].url}?w=768`
  const srcMedium = `${main_image.value[0].url}?w=1024`
  const srcLarge = `${main_image.value[0].url}?w=1688`
  const srcOriginal = `${main_image.value[0].url}`

  return (
    <Container>
      <ImgWrapper>
        {currentBreakpoint === 'phone' ? (
          <>
            <picture>
              <source
                srcSet={srcMobile}
                media="(min-width: 0px) and (max-width: 767px)"
              ></source>
              <source srcSet={srcMobile}></source>
              <Img src={srcMobile} />
            </picture>
          </>
        ) : (
          <picture>
            <source
              media="(min-width: 767px) and (max-width: 1279px) and (min-resolution: 190dpi)"
              srcSet={srcMedium}
            ></source>
            <source
              media="(min-width: 767px) and (max-width: 1079px)"
              srcSet={srcMedium}
            ></source>
            <source
              media="(min-width: 1080) and (max-width: 1279px)"
              srcSet={srcMedium}
            ></source>
            <source
              media="(min-width: 1280px) and (min-resolution: 190dpi)"
              srcSet={srcLarge}
            ></source>
            <source media="(min-width: 1280px)" srcSet={srcLarge}></source>
            <source srcSet={srcLarge}></source>
            <Img src={srcOriginal} />
          </picture>
        )}
      </ImgWrapper>
    </Container>
  )
}

Features.propTypes = {
  elements: PropTypes.shape({
    main_image: PropTypes.object,
    main_image_mobile: PropTypes.object,
  }),
  currentBreakpoint: PropTypes.string,
}

export default withCurrentBreakpoint(Features)
