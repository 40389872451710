import styled from 'styled-components'
import media from 'styles/media'

export const Container = styled.div`
  width: 100%;
  padding: 140px 248px;

  ${media.desktop`
    padding: 140px 136px;
  `}

  ${media.tablet`
    padding: 54px 24px;
  `}
`

export const MainWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  max-width: 2560px;
  margin: 0 auto;

  ${media.desktop`
    justify-content: center;
  `}

  ${media.tablet`
    flex-flow: row wrap;
  `}
`

export const TextWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 60%;
  padding-right: 100px;

  ${media.tablet`
    padding-right: 0;
    width: 100%;
    max-width: 820px;
  `}
`

export const Text = styled.div`
  font-family: 'CeraPro', sans-serif;
  font-size: 24px;
  line-height: 40px;

  ${media.tablet`
    font-size: 20px;
    line-height: 28px;
  `}

  & h4 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  & p {
    opacity: 0.5;
    margin-top: 16px;
  }
`

export const ImagesWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 40%;

  ${media.tablet`
    flex-flow: row;
    justify-content: space-between;
    margin-top: 100px;
    width: 100%;
  `}

  ${media.phone`
    flex-flow: column;
    width: 100%;
    margin-top: 50px;
  `}
`

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;

  &:first-child {
    ${media.tablet`
      margin-bottom: 0;
    `}

    ${media.phone`
      margin-bottom: 25px;
    `}
  }
`

export const Img = styled.img`
  display: flex;
  width: 100%;
  margin: 0 auto;
  height: 100%;

  ${media.tablet`
    width: 80%;
  `}

  ${media.phone`
    width: 100%;
    max-width: 420px;
  `}
`
