import React from 'react'
import PropTypes from 'prop-types'
import Container from 'components/Container'
import Headline from 'components/Headline'
import { grey } from 'const/colors'
import { P } from 'components/Heading'
import {
  Slide,
  CarouselImg as Carousel,
  MemberName,
  MemberDescription,
  TaglineStyled,
  PageSectionStyled,
  MembersContainer,
} from './styles'
import Swiper from 'react-id-swiper'
import withCurrentBreakpoint from 'hoc/withCurrentBreakpoint'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'
import ScrollAnimation from 'react-animate-on-scroll'
import 'react-id-swiper/src/styles/css/swiper.css'

const Grabbable = styled.div`
  &:hover {
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
`

const CarouselImg = styled(Carousel)`
  max-width: 100%;
`

class Leadership extends React.Component {
  renderLeadershipMembers = (members) => {
    let memberElements = []

    members.map(({ id, elements: { name, job_title, image, description } }) => {
      const srcLarge = `${image.value[0].url}?w=904`
      memberElements = [
        ...memberElements,
        <Slide teamMember key={id}>
          <CarouselImg src={srcLarge} />
          <MemberName>{name.value}</MemberName>
          <P margin="5px 0 10px" fontSize="16px" opacity="0.75">
            {job_title.value}
          </P>
          <MemberDescription
            dangerouslySetInnerHTML={{ __html: description.resolvedHtml }}
          />
        </Slide>,
      ]
    })

    return memberElements
  }

  render() {
    const {
      elements: { team_title, team_description, team_members },
      currentBreakpoint,
    } = this.props

    return (
      <PageSectionStyled padding bgColor={grey}>
        <Container maxWidth="820px">
          <Headline>{team_title.value}</Headline>
          <TaglineStyled padding="0 0 20px" richText>
            {team_description.value}
          </TaglineStyled>
        </Container>
        <Container>
          {(currentBreakpoint === 'tablet' ||
            currentBreakpoint === 'desktop' ||
            currentBreakpoint === 'desktopLarge') && (
            <MembersContainer>
              {this.renderLeadershipMembers(team_members)}
            </MembersContainer>
          )}
        </Container>
        <LazyLoad offset={100}>
          <ScrollAnimation animateIn="fadeIn">
            <Grabbable>
              {currentBreakpoint === 'phone' && (
                <Swiper
                  {...{
                    slidesPerView: 1.5,
                    spaceBetween: 18,
                    centeredSlides: true,
                    loop: true,
                  }}
                >
                  {this.renderLeadershipMembers(team_members)}
                </Swiper>
              )}

              {/* {currentBreakpoint === 'tablet' && (
                <Swiper
                  {...{
                    slidesPerView: 4,
                    spaceBetween: 24,
                    centeredSlides: true,
                    loop: true,
                  }}
                >
                  {this.renderLeadershipMembers(team_members)}
                </Swiper>
              )} */}
            </Grabbable>
          </ScrollAnimation>
        </LazyLoad>
      </PageSectionStyled>
    )
  }
}

Leadership.propTypes = {
  elements: PropTypes.object,
  currentBreakpoint: PropTypes.string,
}

export default withCurrentBreakpoint(Leadership)
