import media from 'styles/media'
import Link from 'components/Link'
import Button from 'components/Button'
import Container from 'components/Container'
import styled, { css } from 'styled-components'
import { color } from 'const/colors'

export const Overlay = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 999;
  overflow: visible;
  overflow-y: scroll;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  background-color: ${color.primary.o100};

  transition: opacity 0.3s;

  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter-active {
    opacity: 1;
  }

  &.fade-exit {
    opacity: 1;
  }

  &.fade-exit-active {
    opacity: 0;
  }
`

export const ContentContainer = styled.div`
  ${media.tablet`
    padding: 38px 24px 118px 24px;
  `}
`

export const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 88px);
  align-items: center;

  ${media.tablet`
    justify-content: center;
  `}

  ${media.phone`
    height: auto;
  `}
`

export const ResponseWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  align-content: flex-start;

  ${media.tablet`
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
  `}

  ${media.phone`
    padding: 88px 0 44px 0;  
  `}
`

export const ContainerStyled = styled(Container)`
  display: flex;
  flex-direction: row;
  align-content: flex-start;

  ${media.tablet`
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
  `}

  ${media.phone`
    margin: 88px 0 44px 0;
    width: 100%;
  `}
`

export const TextStyles = css`
  color: ${color.white.o100};
  font-size: 20px;
  line-height: 28px;
  margin-top: none;

  a {
    margin-left: 10px;
    display: inline-block;
    line-height: 32px;
  }

  ${media.tablet`
    font-size: 20px;
    line-height: 28px;

    a {
      margin-left: 0;
      display: block;
    }
  `}
`

export const SubInputStyles = css`
  color: ${color.white.o100};
  font-size: 18px;
  line-height: 28px;
  word-break: break-word;
  margin-bottom: 25px;
`

export const Close = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 34px;
  margin: 48px 48px 0 0;
  cursor: pointer;

  & > * {
    display: flex;
    align-self: center;
  }

  & > span {
    padding-left: 5px;
  }

  ${media.tablet`
    margin: 38px 30px 0 0;
  `}

  ${media.phone`
    border: none;

    & span {
      display: none;
    }

    &:hover {
      background-color: ${color.transparent};
    }
  `}
`

export const SubmitBtn = styled(Button)`
  box-sizing: border-box;
  font-size: 18px;
  margin: 50px 0 0;
  width: 161px;
  padding: 15px 0;
  white-space: nowrap;
  border: 2px solid ${color.white.o100};

  &:disabled {
    border: 2px solid ${color.transparent};
    cursor: auto;

    &:hover:before {
      right: 100%;
    }
  }
`

export const BackBtn = styled(Button)`
  width: 175px;
  height: 60px;
  margin: 30px 0 0 !important;
  font-size: 18px;
  padding: 0;
`

export const LinkStyled = styled(Link)`
  margin: 32px 0 0 48px;

  ${media.tablet`
    margin: 32px 0 0 40px;
  `}

  ${media.phone`
    margin: 32px 0 0 24px;
  `}
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const Heading = styled.h1`
  font-size: 44px;
  line-height: 64px;
  color: ${color.white.o100};
  margin: 0 0 10px;

  ${media.tablet`
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 30px;
  `}
`

export const SubHeading = styled.h2`
  font-size: 36px;
  line-height: 48px;
  color: ${color.white.o100};
  margin: 0 0 10px;
  font-weight: normal;

  ${media.tablet`
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 30px;
  `}
`

export const TalkSection = styled.div`
  display: flex;
  flex-flow: column;
  align-content: flex-start;
  margin-right: 300px;

  ${media.desktop`
    min-width: 400px;
    margin-right: 200px;
  `}

  ${media.tablet`
    margin-right: 0;
    min-width: 0;
    width: 100%;
  `}
`

export const FormWrapper = styled.form`
  display: flex;
  flex-flow: column nowrap;

  ${media.tablet`
    margin-top: 50px;
    width: 100%;
  `}
`

export const FormInput = styled.input`
  box-sizing: border-box;
  border: none;
  outline: none;
  max-width: 465px;
  width: 100%;
  min-height: 45px;
  height: auto;
  margin-bottom: 50px;
  caret-color: ${color.white.o100};
  background-color: ${color.transparent};
  border-bottom: 1px solid ${color.white.o100};
  border-radius: 0;
  padding: 0;
  font-family: inherit;
  font-size: 24px;
  line-height: 40px;
  color: ${color.white.o100};
  transition: background-color 0.2s;
  &::placeholder {
    color: ${color.white.o75};
  }

  ${media.tablet`
    font-size: 20px;
    &::placeholder {
      font-size: 20px;
    }
  `}

  -webkit-box-shadow: 0 0 0px 1000px ${color.primary.o100} inset;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: ${color.white.o100};
  }
`

export const FormTextArea = styled.textarea`
  box-sizing: border-box;
  border: none;
  outline: none;
  max-width: 465px;
  width: 100%;
  height: 45px;
  margin-bottom: 25px;
  caret-color: ${color.white.o100};
  background-color: ${color.transparent};
  border-bottom: 1px solid ${color.white.o100};
  border-radius: 0;
  padding: 0;
  font-family: inherit;
  font-size: 24px;
  line-height: 40px;
  color: ${color.white.o100};
  transition: background-color 0.2s;

  &::placeholder {
    color: ${color.white.o75};
  }

  ${media.tablet`
    font-size: 20px;
    &::placeholder {
      font-size: 20px;
    }
  `}
`
