import React from 'react'
import PropTypes from 'prop-types'
import sizeMe from 'react-sizeme'
import toPx from 'to-px'
import { breakpoints } from 'styles/breakpoints'

function withCurrentBreakpoint(Component) {
  class sizedComponent extends React.Component {
    render() {
      const { size } = this.props
      const width = size.width
      const tabletBreakpoint = toPx(breakpoints.tablet)
      const desktopBreakpoint = toPx(breakpoints.desktop)
      const largeDesktopBreakpoint = toPx(breakpoints.desktopLarge)

      let currentBreakpoint
      if (width > 0 && width <= tabletBreakpoint) {
        currentBreakpoint = 'phone'
      } else if (width > tabletBreakpoint && width <= desktopBreakpoint) {
        currentBreakpoint = 'tablet'
      } else if (width > desktopBreakpoint && width <= largeDesktopBreakpoint) {
        currentBreakpoint = 'desktop'
      } else if (width > largeDesktopBreakpoint) {
        currentBreakpoint = 'desktopLarge'
      }

      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      return <Component currentBreakpoint={currentBreakpoint} {...this.props} />
    }
  }

  sizedComponent.propTypes = {
    size: PropTypes.object,
  }

  return sizeMe()(sizedComponent)
}

export default withCurrentBreakpoint
