import React from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import ScrollAnimation from 'react-animate-on-scroll'
import {
  Container,
  MainWrapper,
  TextWrapper,
  Text,
  ImagesWrapper,
  ImgWrapper,
  Img,
} from './styles'

const Overview1 = (props) => {
  const {
    elements: { overview_text_1, overview_image_1 },
  } = props

  return (
    <Container>
      <MainWrapper>
        <TextWrapper>
          <Text
            dangerouslySetInnerHTML={{ __html: overview_text_1.resolvedHtml }}
          />
        </TextWrapper>
        <ImagesWrapper>
          <ImgWrapper>
            <LazyLoad offset={100}>
              <ScrollAnimation animateIn="fadeIn">
                <Img
                  src={overview_image_1.value[0].url}
                  alt={overview_image_1.value[0].name}
                />
              </ScrollAnimation>
            </LazyLoad>
          </ImgWrapper>
          {/* <ImgWrapper>
            <LazyLoad offset={100}>
              <ScrollAnimation animateIn="fadeIn">
                <Img
                  src={overview_image_1.value[1].url}
                  alt={overview_image_1.value[1].name}
                />
              </ScrollAnimation>
            </LazyLoad>
          </ImgWrapper> */}
        </ImagesWrapper>
      </MainWrapper>
    </Container>
  )
}

Overview1.propTypes = {
  elements: PropTypes.shape({
    overview_text_1: PropTypes.object,
    overview_image_1: PropTypes.object,
  }),
}

export default Overview1
