import React from 'react'
import PropTypes from 'prop-types'
import Text from 'components/Container/text'
import Link from 'components/Link'
import { color } from 'const/colors'
import { Hide } from '@rebass/hide'
import CloseIcon from 'components/Icons/Close'
import {
  Overlay,
  ContainerStyled,
  Wrapper,
  ResponseWrapper,
  TextStyles,
  Close,
  Heading,
  SubHeading,
  FormWrapper,
  ContentContainer,
  Header,
  TalkSection,
  FormInput,
  FormTextArea,
  SubInputStyles,
  SubmitBtn,
  BackBtn,
} from './styles'
import validator from 'validator'

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class ContactFormModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      botfield: '',
      nameKey: 'name',
      name: '',
      emailKey: 'email',
      email: '',
      messageKey: 'message',
      message: '',
      showResponse: false,
      isSendFail: false,
      invalidEmail: true,
      captcha: false,
      closeIconColor: color.white.o100,
    }
  }

  onCloseHover = () => {
    this.setState({ closeIconColor: color.primary.o100 })
  }

  onCloseLeave = () => {
    this.setState({ closeIconColor: color.white.o100 })
  }

  onInputChange = (e) => {
    e.preventDefault()
    e.persist()
    this.setState({ [e.target.name]: e.target.value }, () => {
      e.target.name === 'email' && this.validateEmail()
    })
  }

  componentDidMount() {
    document
      .getElementById('contact-us-from')
      .addEventListener('submit', this.onFormSubmit)
  }

  validateEmail() {
    const isInvalidEmail = !validator.isEmail(this.state.email)
    this.setState({ invalidEmail: isInvalidEmail })
  }

  onFormSubmit = (e) => {
    e.preventDefault()

    if (this.state.botfield !== '') {
      return // Stop form submission
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact-us',
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
      }),
    })
      .then(() =>
        this.setState({
          showResponse: true,
        })
      )
      .catch(
        (error) =>
          error &&
          this.setState({
            isSendFail: true,
          })
      )
  }

  render = () => {
    const { closeContactFormModal, subject, ...restProps } = this.props
    const {
      nameKey,
      emailKey,
      messageKey,
      showResponse,
      isSendFail,
    } = this.state
    return (
      <>
        <Overlay {...restProps}>
          {!showResponse ? (
            <ContentContainer>
              <Header>
                <Hide xsmall small>
                  <Close
                    onClick={closeContactFormModal}
                    color={color.white.o100}
                    padding={'0 15px'}
                    secondaryColor={color.white.o100}
                    secondaryTextColor={color.primary.o100}
                    onMouseOver={this.onCloseHover}
                    onMouseLeave={this.onCloseLeave}
                  >
                    <CloseIcon size={12} color={this.state.closeIconColor} />
                    <span>Close</span>
                  </Close>
                </Hide>
                <Hide medium large xlarge>
                  <CloseIcon
                    size={20}
                    color={this.state.closeIconColor}
                    onClick={closeContactFormModal}
                  />
                </Hide>
              </Header>
              <Wrapper>
                <ContainerStyled maxWidth="none">
                  <TalkSection>
                    <Hide xsmall small>
                      <Heading>Let&apos;s talk&nbsp;{subject}...</Heading>
                    </Hide>
                    <Hide medium large xlarge>
                      <Heading>Let&apos;s talk&nbsp;{subject}</Heading>
                    </Hide>
                    <Text textStyles={TextStyles}>
                      Don&apos;t like forms?
                      <Link
                        externalLink={true}
                        to="mailto:studios@distology.com"
                        size={'24px'}
                        color={color.white.o100}
                        lineColor={color.white.o100}
                        lHeight={'28px'}
                        underline
                      >
                        studios@distology.com
                      </Link>
                    </Text>
                  </TalkSection>
                  <FormWrapper
                    id="contact-us-from"
                    name="contact-us"
                    data-netlify-honeypot="bot-field"
                    data-netlify="true"
                    action="/"
                    onSubmit={this.onFormSubmit}
                    hidden
                  >
                    <input
                      type="hidden"
                      name="bot-field"
                      value={this.state.botfield}
                    />
                    <input type="hidden" name="form-name" value="contact-us" />

                    <FormInput
                      type="text"
                      name={nameKey}
                      value={this.state.name}
                      placeholder="Your name"
                      onChange={this.onInputChange}
                    />
                    <FormInput
                      type="email"
                      name={emailKey}
                      value={this.state.email}
                      placeholder="Your email"
                      onChange={this.onInputChange}
                    />
                    <FormTextArea
                      name={messageKey}
                      value={this.state.contact}
                      placeholder="About your project"
                      onChange={this.onInputChange}
                    ></FormTextArea>
                    <Text textStyles={SubInputStyles}>
                      E.g. brief/timeline/company/location/budget?
                    </Text>
                    <div data-netlify-recaptcha="true"></div>
                    <SubmitBtn
                      type="submit"
                      color={`${color.white.o100}`}
                      secondaryTextColor={`${color.primary.o100}`}
                      disabled={
                        !this.state.name ||
                        !this.state.email ||
                        !this.state.message ||
                        this.state.invalidEmail
                      }
                    >
                      Send message
                    </SubmitBtn>
                  </FormWrapper>
                </ContainerStyled>
              </Wrapper>
            </ContentContainer>
          ) : (
            <Wrapper>
              <ResponseWrapper>
                <Heading>
                  {isSendFail ? 'Message not sent' : 'Message sent'}
                </Heading>
                <SubHeading>
                  {isSendFail
                    ? 'Oops - something went wrong. Please try again'
                    : `Thank you, we'll get back to you very soon...`}
                </SubHeading>
                <BackBtn
                  color={`${color.white.o100}`}
                  secondaryTextColor={`${color.primary.o100}`}
                  onClick={closeContactFormModal}
                >
                  Back to our site
                </BackBtn>
              </ResponseWrapper>
            </Wrapper>
          )}
        </Overlay>
      </>
    )
  }
}

export default ContactFormModal

ContactFormModal.propTypes = {
  closeContactFormModal: PropTypes.func,
  subject: PropTypes.string,
}
