import styled from 'styled-components'
import media from 'styles/media'

export const Container = styled.div`
  width: 100%;
  padding: 70px 248px 140px;

  ${media.desktop`
    padding: 54px 136px;
  `}

  ${media.tablet`
    padding: 54px 24px;
  `}
`

export const ImgWrapper = styled.div`
  margin: 0 auto;
  max-width: 2560px;
`

export const Img = styled.img`
  width: 100%;
`
